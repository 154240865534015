import useJwt from "@/auth/jwt/useJwt";
import moment from 'moment'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, payload) {
      return new Promise((resolve, reject) => {
        if (payload.filter=="important"){
          payload.isImportant=true;
        }else if (payload.filter=="completed"){
          payload.isCompleted=true;
        }else if (payload.filter=="deleted"){
          payload.isDeleted=true;
        }
        //console.log(payload);
        useJwt.get('task',{params:payload})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        useJwt
          .post('task', { 
            title: taskData.title,
            dueDate: moment(taskData.dueDate).format('DD/MM/YYYY'),
            isDeleted: taskData.isDeleted?1:0,
            isCompleted: taskData.isCompleted?1:0,
            isImportant: taskData.isImportant?1:0,
            tags: taskData.tags.join(','),
            description: taskData.description,
            assignee: taskData.assignee.id
           })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        useJwt
          .put(`task/${task.id}`, { 
            title: task.title,
            dueDate: task.dueDate,
            isDeleted: task.isDeleted?1:0,
            isCompleted: task.isCompleted?1:0,
            isImportant: task.isImportant?1:0,
            tags: task.tags.join(','),
            description: task.description,
            assignee: task.assignee.id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt
          .delete(`task/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
